<template>
  <div>
    <div class="screenSizeLap">
      <div class="login-main-container">
        <div class="h-100 d-flex align-items-center justify-content-center">
          <!-- do bind the style vth height if the typing test is over -->
          <div
            class="login-main-sub-container position-relative"
            style="padding: 10px"
          >
            <b-row class="m-0 w-100">
              <b-col class="p-0">
                <div
                  class="w-100 d-flex justify-content-center align-items-baseline"
                >
                  <!-- <img src="../assets/newimgs/GREYLOGO.svg" alt="" /> -->
                  <img
                    src="https://d33yrtc8qntbdn.cloudfront.net/Hire3xAssests/Logo/SVG/Hire3x_Website_Header.svg"
                    style="
                      display: block;
                      visibility: visible;
                      opacity: 1;
                      height: 40px;
                    "
                    alt=""
                  />
                </div>
              </b-col>
            </b-row>
            <b-row class="m-0 w-100">
              <b-col class="p-0">
                <div
                  class="d-flex align-items-center flex-column mt-3 text-center remarkdetails-container"
                  style="padding: 10px; height: calc(100vh - 83px)"
                >
                  <div class="w-50 d-flex justify-content-center flex-column">
                    <h1 class="login-rightcont-headtxt mt-3">Give Feedback</h1>
                    <div class="completed-box-heading mt-2">
                      You have completed the assessment
                    </div>
                    <div class="mt-2">
                      <div
                        class="d-flex align-items-center justify-content-center flex-column mt-5"
                      >
                        <div
                          class="dodont-cardcontainer h-auto position-relative p-4"
                        >
                          <div class="fdbck-icon">
                            <img
                              src="../assets/newui-img/thumbsup.svg"
                              alt=""
                            />
                          </div>
                          <div
                            v-for="(i, index) in remarks"
                            :key="index"
                            class="mt-2"
                          >
                            <div v-if="i.type == 'star'">
                              <div class="fdbck-question-txt2">
                                {{ i.question }}
                              </div>
                              <div class="fdbckrating">
                                <b-form-rating
                                  variant="primary"
                                  class="mb-2"
                                  v-model="i.answer"
                                ></b-form-rating>
                              </div>
                            </div>
                            <div
                              class="mt-5 remarkstextarea position-relative"
                              v-if="i.type == 'remark'"
                            >
                              <b-form-textarea
                                id="textarea"
                                placeholder="What would have been better?"
                                rows="3"
                                max-rows="4"
                                v-model="i.answer"
                                maxlength="1024"
                                @keydown="limitCharacters"
                                @keyup="truncateText(i)"
                              ></b-form-textarea>
                              <!-- <div class="character-count fdbck-question-txt">
                                {{
                                  1024 -
                                    i.answer.length +
                                    " characters remaining"
                                }}
                              </div> -->
                            </div>
                          </div>
                          <div
                            class="mt-4 ml-5 d-flex align-items-center justify-content-center bluebtns"
                          >
                            <b-button variant="primary" @click="complete()"
                              >Submit & Go to Hire3x</b-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <div class="screenSizeMobile">
      <MFeedbackVue />
    </div>
  </div>
</template>

<script>
import MFeedbackVue from "../components/mobileView/MFeedback.vue";
import feedBack from "../mixins/feedBack";
export default {
  name: "Remarks",
  components: {
    MFeedbackVue,
  },
  mixins: [feedBack],
  methods: {
    limitCharacters(event) {
      let key = event.which || event.keyCode;
      event.target.value.length >= 1024 &&
        key != 8 &&
        key != 46 &&
        event.preventDefault();
    },
    truncateText(field) {
      field.answer = field.answer.substr(0, 1024);
    },
  },
};
</script>
<style>
.remarkstextarea .form-control {
  background: #ffffff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
}
.b-rating-icon .text-primary {
  color: #20639b !important;
}
</style>
<style scoped>
.character-count {
  text-align: right;
  font-size: 14px;
}
@media only screen and (max-width: 821px) {
  .screenSizeLap {
    display: none;
  }
}
@media only screen and (min-width: 821px) {
  .screenSizeMobile {
    display: none;
  }
}
/* .b-rating:not(.disabled):not(.readonly):focus:not(:hover) .b-rating-star.focused .b-rating-icon, .b-rating:not(.disabled):not(.readonly) .b-rating-star:hover .b-rating-icon {
    /* transform: scale(1.5); 
} */
.rating-input-main .form-control {
  border: unset;
  padding: 0px;
  background: none;
}
.remark-inner-container {
  width: 100%;
  margin: 62px 100px;
}
.remark-main-container {
  height: calc(100vh - 70px);
  position: relative;
  display: flex;
  place-content: center;
  place-items: center;
  top: 70px;
}
.remarkleft-container {
  width: 50%;
  background: rgba(244, 244, 244, 1);
  padding: 20px;
}
.remarkleft-container-heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #464667;
}
.remarkright-container {
  width: 50%;
}

.rating-div {
  padding: 20px;
  background: #fbfbfb;
  border: 0.7px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(86, 86, 86, 0.13);
  border-radius: 5px;
}
.rating-heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #72738e;
}

.topDiv > .inputField1 {
  background: #fdfdfd;
  border: 0.7px solid rgba(10, 86, 200, 0.5);
  box-sizing: border-box;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 8px 0px 0px 14px;
}
.rating-input {
  color: #ffc145;
}

.right-image {
  padding: 109px 0px 0px 63px;
}
.fieldsetStyle1 {
  color: var(--bg-tertiary);
  background-color: #f3f5f4;
  position: absolute;
  left: 0.4vw;
  top: -12px;
  font-weight: var(--weight-LM);
  font-size: var(--size-12);
  line-height: 18px;
  z-index: 5;
  padding-left: 6px;
  padding-right: 4px;
}
.b-rating .b-rating-star {
  padding-right: 40px;
}
</style>
